.AntiHacker_Mundo_Container {
    background: url('../../../assets/img/img-antihackers/background-inicio-antihackers.png') no-repeat center;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    text-align: center;
}

.AntiHacker_Mundo_Container .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: .4;
}

.AntiHacker_Mundo_Container h1 {
    color: orange;
    background-color: #fff;
    text-align: center;
    border-radius: 20px;
    padding: 2px 30px;
    margin-bottom: 20px;
}

.AntiHacker_Mundo_Container h2 {
    max-width: 150px;
    color: #fff;
}

.AntiHacker_Mundo_Container h4 {
    margin-bottom: 20px;
    margin-top: 20px;
}


.AntiHacker_Mundo_Container h4 {
    width: 500px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    padding: 12px;
}


.modal-content h2 {
    width: auto;
    flex-direction: column;
    color: #272447;
    text-shadow: none;
    background-color: rgba(127, 255, 212, 0.3);
    padding: 10px 20px;
    padding-right: 200px;
    border-radius: 50px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.modal-content p {
    color: #313131;
    margin-bottom: 15px;
   
}

.modal-content img {
    margin-bottom: 15px;
   
}


.close {
    flex-direction: column;
    color: #ffffff;
    background-color: rgb(43, 36, 255);
    margin-top: 10px;
    padding: 10px 20px;
    border-radius: 50px;
    text-align: center;
    justify-content: center;
    align-items: center;
}


/* Estilos para pantallas pequeñas */
@media (max-width: 768px) {
    .AntiHacker_Mundo_Container {
        flex-direction: column;
        gap: 20px;
        padding: 20px;
    }

    .AntiHacker_Mundo_Container h4 {
        width: 100%;
        margin-bottom: 5px;
        margin-top: 5px;
    }
}

/* Ajustes específicos para iPhone SE (375x667) */
@media (max-width: 375px) {
    .AntiHacker_Mundo_Container h1 {
        padding: 2px 15px;
        font-size: 18px;
    }

    .AntiHacker_Mundo_Container h2 {
        max-width: 100px;
        font-size: 14px;
    }

    .AntiHacker_Mundo_Container h4 {
        width: 280px;
        font-size: 12px;
        padding: 8px;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
    z-index: 1000;
}

.modal-content {
    width: 90%;
    max-width: 600px;
    max-height: 90vh; /* Limitar la altura máxima al 90% del viewport */
    padding: 20px;
    overflow-y: auto; /* Hacer scroll vertical si el contenido es más alto que el contenedor */
    background-color: #f1feff;
}