.jackpot-game {
  background-image: url('../../assets/img/background-jackpot.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  font-family: Arial, sans-serif;
}

.machine-slot {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px; /* Limita el tamaño de la máquina */
}

.machine-slot img {
  width: 100%;
  height: auto;
}

.slot-machine {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.slot {
  font-size: 2rem;
  margin: 0 5px;
  padding: 10px;
  border: 4px solid #961212;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffca81;
  border-radius: 10px;
}

.slot img {
  width: 60px; /* Ajusta el tamaño de las imágenes */
  height: 60px;
}

.jackpot-game button {
  width: 150px;
  height: 40px;
  margin-top: 20px;
  padding: 10px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #ff7f1d !important;
  color: rgb(223, 223, 223);
  border-radius: 50px;
}

.jackpot-game button:hover {
  color: #fff;
  background-color: #393939 !important;
}

.jackpot-game button:disabled {
  background-color: #a3ffac !important;
  cursor: not-allowed;
}

.estilos-mensaje-recordatorio{
  padding: 20px;
  text-align: center;
  background-color: rgba(249, 249, 249, 0.7);
  border-radius: 15px;
  border-radius: 10px;
  margin-top: 20px;
  color: #c44e18;
}

.logo-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.logo-container img {
  width: 250px; /* Ajusta el tamaño del logo para pantallas pequeñas */
  height: auto;
}

/* Media queries para dispositivos pequeños */
@media (max-width: 768px) {
  .jackpot-game {
    height: auto;
    padding: 20px;
  }

  .machine-slot {
    margin-top: 100px;
    max-width: 320px; /* Reduce el tamaño de la máquina para pantallas más pequeñas */
  }

  .slot {
    font-size: 1.5rem;
    width: 40px;
    height: 40px;
  }

  .slot img {
    width: 40px;
    height: 40px;
  }

  .jackpot-game button {
    margin-top: 50px;
    width: 130px;
    font-size: 0.9rem;
  }

  .logo-container img {
    margin-top: 50px;
    width: 200px;
  }
}

@media (max-width: 375px) {
  .machine-slot {
    margin-top: 70px;
    max-width: 290px; /* Más pequeño para iPhone SE */
  }

  .slot {
    font-size: 1.2rem;
    width: 35px;
    height: 35px;
  }

  .slot img {
    width: 35px;
    height: 35px;
  }

  .jackpot-game button {
    width: 120px;
    font-size: 0.8rem;
  }

  .logo-container img {
    margin-top: 0;
    width: 180px;
  }
}
