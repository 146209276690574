.QuestionScreenContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-image: url('../../../assets/img/background-jackpot.png'); /* Cambia la ruta a la imagen que desees usar */
  background-size: cover; /* Hace que la imagen cubra todo el fondo */
  background-position: center; /* Centra la imagen en el fondo */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.QuestionContainer {
  width: 100%;
  margin: 36px;
}

.Question {
  width: 70%;
  margin: auto;
  margin-top: 10px;
  display: flex;
  border-radius: 15px;
  background-color: #FF7C00;
  padding: 5%;
  justify-content: center;
  align-items: center;
  font-family: 'Banda Regular', sans-serif;
  font-size: 26px;
  font-weight: 300;
  color: #fff;
  flex: 0; /* No dejar que el contenedor crezca automáticamente */
  height: auto; /* Ajuste dinámico según el contenido */
  text-align: center; /* Opcional: para centrar el texto si es necesario */
  box-sizing: border-box; /* Incluir el padding dentro del tamaño del contenedor */
}


.Answers {
  display: flex;
  flex-direction: column;
  width: 95%;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 17px;
}

.Answers > .Answer {
  width: 70%;
  margin: auto;
  display: flex;
  flex: 1;
  flex-direction: center;
  margin: 17px 0;
  border-radius: 8px;
  background-color: #333;
  padding: 26px;
  font-family: 'Banda Regular', sans-serif;
  font-size: 24px;
  font-weight: 300;
  color: #fff;
  justify-content: center;
}

.Answers > .RightAnswer {
  background-color: #7ed321;
}

.Answers > .WrongAnswer {
  background-color: #d0011b;
}

.Answers > .Answer .AnswerEnum {
  width: 30px;
}
.Answers > .Answer .AnswerContent {
  margin-left: 2%;
}

.GameInfoContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  max-height: 200px;
}

.GameInfoContainer > .GameQuestionNumber, .GameInfoContainer > .GameCurrentCategoryName {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: 500;
  color: #fff;
}

@media (max-width: 768px) {
  /* Estilos específicos para pantallas más pequeñas */
  .Question{
    width: 80%;
    font-size: 16px;
    text-align: center;
  }

  .Answers .Answer{
    font-size: 16px;
    text-align: center;
    margin: 5px 0;
  }

  .container_logo > img {
    max-width: 80%;

  }
  
  .container_logo_ruleta > img {
    max-width: 6%;
  }
}
