.Pacman_screen {
    text-align: center;
    background-image: url('../../assets/img/pacman/fondo-seccion-juego-pacman-antifrude.jpg');
    background-size: cover;
    margin:  0;
    min-height: 100vh;
    position: relative;
    overflow: unset;
  }
  
  .board {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .wall {
    background-color: rgb(23, 110, 224); /* Cambiar el color de las paredes */
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .game-logo {
    width: 400px; /* Ajusta el tamaño del logo según sea necesario */
    height: auto;
  }
  
  .controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .horizontal-buttons {
    display: flex;
    justify-content: space-between;
    width: 150px;
    margin: 10px 0;
  }
  
  .control-button {
    background-color: #ffcc00;
    color: #000;
    border: none;
    padding: 15px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .control-button:active {
    background-color: #ff9900;
  }
  
  .control-button:focus {
    outline: none;
  }
  
  .control-button.up {
    margin-bottom: 10px;
  }
  
  .control-button.down {
    margin-top: 10px;
  }
  
  
  
  
  
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
 
  @media (max-width: 600px) {
    .Pacman_screen {
        background-size: contain;

    }
  }