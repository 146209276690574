body{
  margin: 0;
  height: 100%;
  background-image: url(./assets/img/background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@font-face {
font-family: 'MiFuentePersonalizada';
src: url(./assets/Fonts/BANDA_REGULAR.OTF) format('OTF');
font-weight: normal;
font-style: normal;
font-family: 'Banda bold';
src: url(./assets/Fonts/BandaBold-Bold.ttf) format('ttf');
font-weight: normal;
font-style: normal;
font-family: 'MiFuentePersonalizada';
  src: url('./assets/Fonts/BANDA_REGULAR.OTF') format('OTF'),
       url('./assets/Fonts/BandaBold-Bold.ttf') format('ttf'),
       url('./assets/Fonts/BandaLight-Light.ttf') format('ttf'),
       url('./assets/Fonts/BandaSemibold-SemiBold.ttf') format('ttf'),
       url('./assets/Fonts/bold.otf') format('otf');
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-around;
}


header {
  padding-top: 0px; /* Añade relleno para separar el contenido */
  text-align: center; /* Centra el contenido dentro del encabezado */
}

.logo-ciberseguridad {
  height: auto;
  float: none;
  margin-right: 20px;
}

.logo-ciberseguridad img {
  width: 500px;
}


.registro{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.input-container {
  display: flex;
  align-items: center;
  margin: 5px;
  margin-top: 40px;
}

.form_controls {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-family: 'Banda Regular' sans-serif;
}

.form_controls img {
margin-right: 10px;
margin-left: 20px;
background-color: #161616;
border-radius: 15px;
color: #fff;
}

.icono {
  width: 30px;
  height: 30px;
}

.input-container {
  width: 500px;
  height: 55px;
  margin-right: 10px;
  background-color: #161616;
  border-radius: 15px;
  color: #fff;
}

.form_controls input {
  width: 400px;
  border: none; /* Elimina el borde predeterminado */
  outline: none; /* Elimina el contorno al hacer clic */
  padding: 5px; /* Añade relleno según tus preferencias */
  background-color: transparent; /* Fondo transparente */
  font-family: 'Banda Regular', sans-serif;
  color: #fff;
  /* Agrega otros estilos personalizados según tus necesidades */
}

.terminos-y-condiciones{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.cont-check-terms{
  display: flex;
}




/* Ocultar el input checkbox por defecto */
#activar {
  display: none;
}

/* Estilo personalizado para el checkbox */
#activar + label::before {
  content: ''; /* Código Unicode de un símbolo de marca de verificación */
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border: 1px solid #FF7C00;
  border-radius: 4px;
  margin-right: 10px;
  margin-top: 20px;
  cursor: pointer;
  background-color: #fff;
}

/* Estilo para el checkbox cuando está marcado */
#activar:checked + label::before {
  content: '\2713'; /* Símbolo de marca de verificación */
  background-color: #FF7C00; /* Cambia el fondo cuando está marcado */
  color: #fff; /* Cambia el color del símbolo */
}


.text-terms{
  margin-left: 10px;
  text-align: center;
  font-family: 'Banda Regular', sans-serif;
}

.resaltado{
color: #FF7C00;
}

#miPopUp {
display: none; /* Inicialmente oculto */
margin: 0 auto;
}

.popUp {
position: fixed;
margin: 0 auto;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.7);
display: flex;
justify-content: center;
align-items: center;
}

.popup-contenido {
width: 100%;
height: 80%;
background: white;
padding: 20px;
border-radius: 5px;
text-align: center;
justify-content: center;
align-items: center;
}

#abrirPopup {
cursor: pointer; /* Cambia el cursor al puntero cuando se pasa sobre el enlace */
text-decoration: none; /* Subraya el texto para indicar que es un enlace */
/* Otros estilos como fondo, bordes, etc., según tus preferencias */
}

.cerrar-popup {
position: absolute;
top: 85%;
right: 50%;
font-size: 60px;
cursor: pointer;
color: #fff;
}

iframe {
width: 70%;
height: 100%; /* Ajusta la altura según tus necesidades */
border: none;
}

.boton-start {
  display: flex;
  text-align: center;
  justify-content: center;
  padding-top: 15px;
  transition: all 0.2s;
  transform: rotate(0deg);
}

.boton-enlace {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  background-color: #FF7C00;
  border: 1px solid #FF7C00;
  border-radius: 15px;
  transition: background-color 0.3s ease;
  transition: all 0.2s;
  transform: rotate(0deg);
  color: #fff;
  cursor: pointer;
}

.boton-enlace > input {
  color: #fff;
  text-decoration: none;
  font-family: 'Banda bold', sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border: none;
  background-color: transparent;
}

.boton-enlace > .loader_button {
  margin-left: 10px;
}

.boton-enlace:hover {
  background-color: #ffecdb;
  color: #161616;
}

.cont-footer{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.naranja{
  margin-top: 0;
}

.naranja img{
  width: 130px;
  margin: 0;
}

.negro{
  margin-top: 0;
}

.negro img{
  width: 130px;
  margin: 0;
}

.cont-select{
  display: flex;
  flex-direction: row;
}

.cont-sel-options{
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.container_gracias {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  padding: 20px;
}

.container_gracias p {
  margin: 10px 0;
}

.titulo-juegos{
  display: flex;
  width: 100%;
}

.titulo-juegos img{
  width: 70%;
  margin: auto;
}


.cont-juegos{
  width: 100%;
  margin-left: 20px;
}

/* Estilos para pantallas pequeñas */
@media (max-width: 768px) {
  .cont-select {
    flex-direction: column; /* Cambia a columna en pantallas pequeñas */
    align-items: center; /* Centra los elementos en el contenedor */
  }

  .cont-sel-options {
    justify-content: center; /* Centra las opciones en pantallas pequeñas */
  }

  .cont-juegos {
    margin-left: 0; /* Elimina el margen izquierdo en pantallas pequeñas */
    margin-top: 10px; /* Añade margen superior para separación */
  }
}

/* Estilos para pantallas muy pequeñas */
@media (max-width: 480px) {
  .cont-select {
    margin: 0 5px; /* Reduce márgenes en pantallas muy pequeñas */
  }

  .cont-sel-options {
    flex-direction: column; /* Cambia a columna para mejorar la legibilidad */
    align-items: center; /* Centra las opciones verticalmente */
  }

  .cont-juegos {
    margin-top: 5px; /* Espaciado menor para pantallas pequeñas */
  }
}

.cont-escoge-juego{
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.escoge-juego{
  display: flex;
  justify-content: center;
  align-items: center;
}

.cont-ruleta{
  flex: 1;
  max-width: 100%;
  margin: auto;
  margin-top: 20px;
  position: relative
}

.cont-ruleta img{
  margin-bottom: 10px;
  width: 80%;
  display: flex;
  justify-content: center;
  margin: auto;
}

.cont-focus{
  flex: 1;
  max-width: 100%;
  margin: auto;
  margin-top: 20px;
  position: relative
}

.cont-focus img{
  margin-bottom: 10px;
  width: 80%;
  display: flex;
  justify-content: center;
  margin: auto;
}

.cont-ninja{
  flex: 1;
  max-width: 100%;
  margin: auto;
  margin-top: 20px;
  position: relative;
  align-items: center;
}

.cont-ninja img{
  margin-bottom: 10px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.boton-ruleta {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
}

.boton-focus {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
}

.boton-ninja {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
}

.boton-enlace-juegos {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  width: 200px;
  background-color: #ffffff;
  color: #2a2a2a;
  text-decoration: none;
  border: 1px solid #fff;
  border-radius: 25px;
  font-family: 'Banda Regular', sans-serif;
  font-size: 18px;
  font-weight: lighter;
  text-align: center;
  transition: background-color 0.3s ease;
  transition: all 0.2s;
  transform: rotate(0deg);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
}

.boton-enlace-juegos:hover {
  background-color: hsl(0, 0%, 15%);
  color: #d4d4d4;
}

.cont-transmision{
  width: 100%;
  display: flex;
}

.ver-en-vivo{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#boton-con-imagen {
  display: flex;
  margin: auto;
  width: 100%; /* Establece el ancho de acuerdo a las dimensiones de tu imagen */
  cursor: pointer; /* Cambia el cursor al puntero al pasar el ratón por encima */
  /* Otros estilos según tus necesidades */
}


#boton-con-imagen:hover img {
  content: url(./assets/img/live-imagen-hover.png); /* Cambia la imagen en hover */
  width: 100%;
}

#boton-con-imagen img {
  width: 100%;
}


/* Estilos para pantallas más pequeñas (máximo 768px de ancho) */
@media (max-width: 768px) {
  /* Estilos específicos para pantallas más pequeñas */
  body {
    font-size: 16px;
  }
  .container {
    width: 90%;
  }
  .logo-ciberseguridad {
    display: flex;
    float: none;
    justify-content: center;
    align-items: center;
  }
  
  .logo-ciberseguridad img {
    max-width: 60%;
    height: auto;
  }

/* Estilos para .registro */
.registro {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; /* Ajusta el margen superior para pantallas más pequeñas */
}

/* Estilos para .input-container */
.input-container {
  width: 90%;
  display: flex;
  flex-direction: column; /* Alinea los elementos verticalmente en pantallas pequeñas */
  margin: 5px 0; /* Aumenta el espacio entre controles */
}

/* Estilos para .form_controls */
.form_controls {
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'Banda Regular', sans-serif; /* Corrige la propiedad de la fuente */
  margin: 8px 0; /* Aumenta el espacio entre controles */
}

.form_controls img {
  margin-right: 10px;
  margin-left: 20px;
  background-color: #161616;
  border-radius: 15px;
  color: #fff;
}

.cont-check-terms{
  display: table-column;
  align-items: center;
  justify-content: center;
}

.text-terms{
  text-align: center;
  font-family: 'Banda Regular', sans-serif;
  font-size: 12px;
}
.naranja img{
  width: 130px;
  margin: 0;
}

.negro img{
  width: 130px;
  margin: 0;
}

.popup-contenido {
  width: 95%;
  height: 80%;
  background: white;
  margin-top: 62px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  }
  .cerrar-popup {
    position: absolute;
    top: 0px;
    right: 20px;
    font-size: 60px;
    cursor: pointer;
    color: #fff;
    }

    .cont-select{
      display: flex;
      flex-direction: column;
      margin-top: 0px;
    }
    
    .cont-sel-options{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    .titulo-juegos{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
    }
    
    .titulo-juegos img{
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: auto;
    }
    
    
    .cont-juegos{
      width: 100%;
      margin-left: 0;
      display: flex;
      flex-direction: column;
    }
    
    .cont-escoge-juego{
      display: flex;
      flex-direction: column;
      border-radius: 15px;
    }
    
    .escoge-juego{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .cont-focus{
      margin: auto;
      margin-top: 50px;
      height: 80%;
    }
    
    .cont-ninja{
      margin: auto;
      margin-top: 50px;
      height: 80%;
    }

    .cont-transmision{
      width: 90%;
      display: flex;
    }
    
    .ver-en-vivo{
      width: 100%;
      margin-top: 50px;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .selector-header{
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 0px; /* Añade relleno para separar el contenido */
      text-align: center; /* Centra el contenido dentro del encabezado */
    }

    .boton-salir{
      margin: 0 auto;
    }
    
}

/* Estilos para pantallas más grandes (más de 768px de ancho) */
@media (min-width: 769px) {
  /* Estilos específicos para pantallas más grandes */
  body {
    font-size: 18px;
  }
  .container {
    width: 80%;
  }
  
}


.container_logo > img {
  max-width: 20%;
}

.container_logo_ruleta > img {
  max-width: 6%;
}


.loader_button {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid gray;
  border-right: 2px solid darkgray;
  border-bottom: 2px solid lightgray;
  border-left: 2px solid gray;
  width: 12px;
  height: 12px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  text-align: center;
  margin: auto;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error_form {
  text-align: center;
  color: red;
  padding: 20px;
}

.boton-enlace-negro {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  background-color: #000;
  border-radius: 15px;
  transition: background-color 0.3s ease;
  transition: all 0.2s;
  transform: rotate(0deg);
  color: #fff;
  cursor: pointer;
}

.boton-enlace-negro:hover {
  background-color:gray;
  color: #fffffD;
}

.boton-salir {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  padding: 10px 10px;
  background-color: #d2643a;
  border: 1px solid #fff;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  transition: all 0.2s;
  transform: rotate(0deg);
  color: #fff;
  cursor: pointer;
}

.boton-salir > input {
  color: #fff;
  text-decoration: none;
  font-family: 'Banda bold', sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border: none;
  background-color: transparent;
}


.selector-header{
  display: flex;
  justify-content: space-between;
  padding-top: 0px; /* Añade relleno para separar el contenido */
  text-align: center; /* Centra el contenido dentro del encabezado */
}
