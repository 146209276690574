.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-image: url('../../../assets/img/pacman/fondo-home-pacman-antifraude.png');
    background-color: #0b0635;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .home-logo {
    max-width: 250px;
    margin-top: 80px;
    margin-bottom: 45px;
  }
  
  .home-text-container {
    text-align: center;
    color: white;
  }

  .home-logo-titulo-juego {
    max-width: 400px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  
  .home-text-container h1 {
    font-size: 48px;
    margin: 0;
  }
  
  .home-text-container h2 {
    font-size: 32px;
    margin: 0;
  }
  
  .home-play-button {
    width: 120px;
    margin-top: 150px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    background-color: orange;
    border: none;
    border-radius: 50px;
    transition: background-color 0.3s ease;
  }
  
  .home-play-button:hover {
    background-color: orange;
  }
  

  /* Ajustes para pantallas móviles */
@media (max-width: 600px) {
  .home-container {
      background-size: cover;
  }

  .home-logo {
      max-width: 180px; /* Reducir el tamaño del logo para pantallas pequeñas */
      margin-top: 80px;
      margin-bottom: 30px;
  }

  .home-logo-titulo-juego {
    margin-top: 60px;
      max-width: 300px; /* Ajustar para que el título no desborde en pantallas pequeñas */
      text-align: center;
  }

  .home-text-container h1 {
      font-size: 32px; /* Tamaño de fuente reducido para h1 */
  }

  .home-text-container h2 {
      font-size: 24px; /* Tamaño de fuente reducido para h2 */
  }

  .home-play-button {
      width: 95px; /* Reducir el tamaño del botón de "Jugar" */
      margin-top: 100px;
      padding: 8px 16px;
      font-size: 14px; /* Ajustar tamaño de fuente del botón */
  }
}