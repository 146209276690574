.pacman-summary-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-image: url('../../../assets/img/pacman/fondo-home-pacman-antifraude.png');
    background-color: #0b0635;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.container-text-summary{
    margin-top: 50px;
    background-color: rgb(13, 13, 56, 0.9);
    max-width: 420px;
    border-radius: 20px;
}

.texto-summary1{
    margin-top: 30px;
    text-align: center;
    color: orange;
    font-size: 26px;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.texto-summary2{
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    color: rgb(169, 175, 255);
    font-size: 26px;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}


/* Media query para pantallas móviles */
@media (max-width: 768px) {
    .pacman-summary-container {
        background-size: cover;
        background-position: top;
    }

    .container-text-summary {
        margin-top: 20px;
        max-width: 80%;
        padding: 10px;
    }

    .texto-summary1, .texto-summary2 {
        font-size: 18px;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    }
    
    .texto-summary1 {
        margin-top: 15px;
    }

    .texto-summary2 {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

/* Media query para pantallas muy pequeñas (por debajo de 480px) */
@media (max-width: 480px) {
    .container-text-summary {
        max-width: 80%;
        padding: 5px;
    }

    .texto-summary1, .texto-summary2 {
        font-size: 16px;
    }
}